import Prism from 'prismjs';
import 'prismjs/plugins/toolbar/prism-toolbar.js';
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.js';
import { lavalamp } from 'lavalamp';


function copyCode() {

  // lavalamp
  $('.lavalamp').lavalamp({
    setOnClick: true,
    enableHover: false,
    margins: false,
    autoUpdate: true,
    duration: 200
  });


  // prismjs
  if (typeof self === 'undefined' || !self.Prism || !self.document) {
    return;
  }

  if (!Prism.plugins.toolbar) {
    console.warn('Copy to Clipboard plugin loaded before Toolbar plugin.');

    return;
  }

  var ClipboardJS = window.ClipboardJS || undefined;

  if (!ClipboardJS && typeof require === 'function') {
    ClipboardJS = require('clipboard');
  }

  var callbacks = [];

  if (!ClipboardJS) {
    var script = document.createElement('script');
    var head = document.querySelector('head');

    script.onload = function() {
      ClipboardJS = window.ClipboardJS;

      if (ClipboardJS) {
        while (callbacks.length) {
          callbacks.pop()();
        }
      }
    };

    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/clipboard.js/2.0.0/clipboard.min.js';
    head.appendChild(script);
  }

  Prism.plugins.toolbar.registerButton('copy-to-clipboard', function (env) {
    var linkCopy = document.createElement('a');
    linkCopy.textContent = 'Copy';

    if (!ClipboardJS) {
      callbacks.push(registerClipboard);
    } else {
      registerClipboard();
    }

    return linkCopy;

    function registerClipboard() {
      var clip = new ClipboardJS(linkCopy, {
        'text': function () {
          return env.code;
        }
      });

      clip.on('success', function() {
        linkCopy.textContent = 'Copied!';

        resetText();
      });
      clip.on('error', function () {
        linkCopy.textContent = 'Press Ctrl+C to copy';

        resetText();
      });
    }

    function resetText() {
      setTimeout(function () {
        linkCopy.textContent = 'Copy';
      }, 5000);
    }
  })
};

copyCode();
