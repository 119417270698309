import "bootstrap";
import "../theme/app.js";
import 'jquery-ui/ui/widgets/tooltip';

import ClipboardJS from 'clipboard';

var a = new ClipboardJS('.anchor', {
  text: function(b) {
    return window.location.host + window.location.pathname + $(b).attr("href")
  }
});

a.on('success', function(e) {
  e.clearSelection(), $(e.trigger).addClass("copied"), setTimeout(function() {
    $(e.trigger).removeClass("copied")
  }, 2000)
});


$("[data-bs-toggle=tooltip").tooltip({
  tooltipClass: "anchor-tooltip",
  position: { my: "center top-38", at: "center top" }
});
